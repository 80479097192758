import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Button } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';

function ViewInvoiceForDraftOrder() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    const draftOrderId = pageSearchParams.get('id');
    httpInterceptor
      .get('/api/order/draft/view', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: draftOrderId,
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
        window.open(axiosRes.data.link as string, '_blank');
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  if (loading) {
    return <CommonScreen loading />;
  }

  if (
    typeof res !== 'object' ||
    (err && (!err.response || err.response.status !== 400))
  ) {
    shopify.toast.show('Something went wrong', {
      duration: 5000,
      isError: true,
    });
    return <CommonScreen loading={false} />;
  }

  if (err) {
    shopify.toast.show(err.response!.data!.message, {
      isError: true,
      duration: 5000,
    });

    return (
      <CommonScreen loading={false}>
        <Button
          onClick={() => {
            navigate('/pricing');
          }}
        >
          Upgrade to paid plan
        </Button>
      </CommonScreen>
    );
  }

  setTimeout(() => {
    navigate(-1);
  }, 3_000);

  return <CommonScreen loading={false} />;
}

export default ViewInvoiceForDraftOrder;
