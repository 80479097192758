import { useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

export default function GoBackOrToAppBtn({
  goBackUrlInAdmin,
}: {
  goBackUrlInAdmin: string;
}) {
  const [isTimeoutCompleted, setIsTimeoutCompleted] = useState(false);
  const navigate = useNavigate();
  const { isSetupCompleted, stepReached } = window.initialData;
  useEffect(() => {
    const timeout = setTimeout(() => {
      // window.history.pushState(null, '', `shopify://admin/${goBackUrlInAdmin}`);
      navigate(`shopify://admin/${goBackUrlInAdmin}`, { replace: true });
      setIsTimeoutCompleted(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [goBackUrlInAdmin, navigate]);

  if (!isSetupCompleted) {
    window.location.pathname = '/';
    return null;
  }
  if (isTimeoutCompleted) {
    return (
      <Button
        loading={false}
        onClick={() => {
          window.location.pathname = isSetupCompleted
            ? '/'
            : stepReached < 4
            ? `/step${stepReached}`
            : '/step3';
        }}
        variant='primary'
      >
        Continue to app
      </Button>
    );
  }

  return <Button disabled>Going Back in 5 seconds</Button>;
}
