import { BlockStack, Button, Image } from '@shopify/polaris';
import { PropsWithChildren } from 'react';

export default function CommonScreen({
  loading,
  children,
}: PropsWithChildren<{
  loading: boolean;
}>) {
  const { isSetupCompleted, stepReached } = window.initialData;
  if (!isSetupCompleted) {
    window.location.pathname = '/';
    return null;
  }
  return (
    <BlockStack inlineAlign='center' align='center' gap='500'>
      <Image
        width={80}
        height={80}
        source='/.netlify/images?url=/images/app-icon.png&w=80&h=80'
        alt='Invoice Hero'
      />
      {children || (
        <Button
          loading={loading}
          onClick={() => {
            window.location.pathname = isSetupCompleted
              ? '/'
              : stepReached < 4
              ? `/step${stepReached}`
              : '/step3';
          }}
          variant='primary'
        >
          Continue to app
        </Button>
      )}
    </BlockStack>
  );
}
