import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';

function ViewInvoiceForOrder() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | string | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError>(null);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    const orderId = pageSearchParams.get('id')!;
    httpInterceptor
      .get('/shopify/generateInvoice/getLink', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: orderId,
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
        if (axiosRes.data.link) {
          const invoiceLink = new URL(axiosRes.data.link);
          invoiceLink.searchParams.append('timestamp', Date.now().toString());
          window.open(invoiceLink.toString(), '_blank');
        }
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  if (loading) {
    return <CommonScreen loading />;
  }

  if (err) {
    shopify.toast.show(
      err.response?.status === 400 &&
        (err.response?.data as any | undefined)?.code === 'PLAN_LIMIT_EXCEEDED'
        ? (err.response.data as any).message
        : 'Something went wrong',
      {
        duration: 5000,
        isError: true,
      },
    );
    return <CommonScreen loading={false} />;
  }

  if (typeof res === 'string') {
    shopify.toast.show(
      'Your order has not proceed yet. Please try again after some time.',
      {
        duration: 5000,
        isError: true,
      },
    );
    return <CommonScreen loading={false} />;
  }
  setTimeout(() => {
    navigate(-1);
  }, 100);

  return <CommonScreen loading={false} />;
}

export default ViewInvoiceForOrder;
