import { StrictMode, useEffect } from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import type { LinkLikeComponentProps } from '@shopify/polaris/build/ts/src/utilities/link/types';
import AppQueryClientProvider from './contexts/react-query.context';
import App from './App';

function LinkLikeComponent(props: LinkLikeComponentProps) {
  const { url } = props;
  return <Link {...props} to={url} />;
}

function Main({ isSetupCompleted }: { isSetupCompleted: boolean }) {
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    if (!isSetupCompleted) {
      navigate('/step1');
    }
    // setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const router = useMemo(
  //   () => ({
  //     location,
  //     history,
  //   }),
  //   [location, history],
  // );

  return (
    <AppQueryClientProvider>
      <AppProvider linkComponent={LinkLikeComponent} i18n={enTranslations}>
        <App />
      </AppProvider>
    </AppQueryClientProvider>
  );
}

export default function renderMainApp(isSetupCompleted = true) {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <StrictMode>
      <Router>
        <Main isSetupCompleted={isSetupCompleted} />
      </Router>
    </StrictMode>,
  );
}
