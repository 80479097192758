import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';
import GoBackOrToAppBtn from '../components/GoBackOrToAppBtn';

function BulkAction({
  url,
  successMessage,
  goBackUrlInAdmin,
}: {
  successMessage: string;
  goBackUrlInAdmin: string;
  url: string;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);
    const ids = pageSearchParams.getAll('ids[]');
    const shop = pageSearchParams.get('shop');

    httpInterceptor
      .get(url, {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          ids,
          shop,
        },
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  if (loading) return <CommonScreen loading />;

  if (err) {
    shopify.toast.show('Something went wrong', {
      isError: true,
      duration: 5000,
    });
    return <CommonScreen loading={false} />;
  }

  shopify.toast.show(successMessage, {
    duration: 5000,
  });

  setTimeout(() => {
    navigate(-1);
  }, 3_000);

  return (
    <CommonScreen loading={false}>
      <GoBackOrToAppBtn goBackUrlInAdmin={goBackUrlInAdmin} />
    </CommonScreen>
  );
}

export default BulkAction;
